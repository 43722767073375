import {Link} from 'react-router-dom';
import './index.scss';

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-content">
                <div className="fp-left">
                    <span>© 北京对牛弹琴科技有限公司</span>
                </div>
                <div className="fp-right">
                    地址：北京市朝阳区将台乡酒仙桥北路乙10号院3号楼星地中心C座7层714室
                    <br />
                    电话：010-53822135
                    <br />
                    <Link to="/about/pod/2" target="_blanka" style={{borderLeft:0}}>
                        ICP证
                    </Link> 
                    <Link to="/about/pod/1" target="_blanka">
                        京网文【2024】4533-212号
                    </Link> 
                    <Link to="https://beian.miit.gov.cn/" target="_blank">
                        京ICP备 2024060716号-1
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Footer;
