// import Header from '@/components/header';

import './index.scss';
// import Footer from '@/components/footer';

import wwwImg from '../../assets/images/www.jpg';
import icpImg from '../../assets/images/icp.jpg';
import {useParams} from 'react-router-dom';

const img = {
    1: wwwImg,
    2: icpImg
};

export default function Pod() {
    const {p} = useParams();

    return (
        <div
            style={{
                minWidth: 900,
                maxWidth: 1024,
                margin:'0 auto',
            }}>
            <img alt="" src={img[p]} width="100%" />
        </div>
    );
    // return <div>abc</div>
}
